<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title
        >Muokkaa maksua # {{ currentPayment.paymentNumber }} |
        {{ currentPayment.apartment.address }}
        <span v-if="currentPayment.apartment.apartmentNumber"
          >, {{ currentPayment.apartment.apartmentNumber }}</span
        ></v-card-title
      >

      <v-card-text>
        <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card-text>

      <!-- JOIN TAB -->
      <v-card-text v-show="tab == 0">
        <v-form v-if="!currentPayment.joinedTo" ref="form">
          <h3>Kohdista maksu # {{ currentPayment.paymentNumber }}</h3>
          <small
            >Voit kohdistaa maksun vain saman vuokrakohteen tulevien kuukausien lähteviin
            maksuihin</small
          >
          <v-row dense>
            <v-col cols="12" sm="8">
              <v-autocomplete
                v-model="outgoingPayment"
                :items="activePayments"
                :item-text="fixedName"
                item-value="_id"
                label="Lähtevä maksu"
                no-data-text="Maksun numero, vuokrakohteen osoite tai viite"
                outlined
                small-chips
                return-object
                deletable-chips
                hide-details
                :rules="required"
                :search-input.sync="searchInput"
                @change="searchInput = ''"
                @input.native="getOutgoingPayment"
              >
              </v-autocomplete>
              <small>K = Kulu, M = Maksu, V = Viite</small>
            </v-col>
          </v-row>
        </v-form>

        <v-row v-else dense>
          <v-col>
            <h3>
              Maksu on jo kohdistettu maksuun # {{ currentPayment.joinedTo.paymentNumber }}, et voi
              kohdistaa sitä uudelleen.
            </h3>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- ATTACHED TAB -->
      <v-card-text v-show="tab == 1">
        <!-- Attached Other Payments -->
        <h3 class="mb-1">Maksuun # {{ currentPayment.paymentNumber }} kohdistetut suoritukset</h3>

        <div v-if="getAttachedPayments(currentPayment.payments).length > 0" top max-width="400">
          <div
            v-for="(payment, index) in getAttachedPayments(currentPayment.payments)"
            :key="index + 'a'"
          >
            <small>{{ formatDate(payment.date) }}</small>
            <p>
              <strong> {{ payment.otherPayment.name }}</strong>
            </p>
            <p>Suoritus: {{ formatCurrency(payment.amount) }}</p>
            <p>Viite: {{ payment.otherPayment.reference }}</p>

            <v-btn
              class="mb-1 mt-1"
              small
              outlined
              color="error"
              @click="
                deleteOtherPayment({
                  otherPaymentId: payment.otherPayment._id,
                  outgoingPaymentId: currentPayment._id,
                })
              "
              >Poista kohdistus</v-btn
            >
            <v-divider
              v-if="index < getAttachedPayments(currentPayment.payments).length - 1"
              class="mb-1 mt-1"
            ></v-divider>
          </div>
        </div>

        <p v-else>Ei kohdistettuja suorituksia muokattavaan maksuun.</p>

        <v-divider
          v-if="
            getAttachedPayments(currentPayment.payments).length > 0 &&
            currentPayment.joinedPayments.length > 0
          "
          class="mb-1 mt-1"
        ></v-divider>

        <!-- Joined Other Payments -->
        <h3 v-if="currentPayment.joinedPayments.length > 0" class="mb-1 mt-2">
          Maksuun # {{ currentPayment.paymentNumber }} kohdistetut muut lähtevät maksut
        </h3>

        <div v-if="currentPayment.joinedPayments.length > 0" top max-width="400">
          <div v-for="(payment, index) in currentPayment.joinedPayments" :key="index + 'b'">
            <small>{{ formatDateMonthName(payment.targetMonth) }}</small>
            <p>
              <strong
                >{{ payment.paymentType == "payment" ? "Maksu" : "Kulu" }}: #
                {{ payment.paymentNumber }}</strong
              >
            </p>
            <p>Avoinna: {{ formatCurrency(payment.openAmount) }}</p>
            <p>Viite: {{ payment.paymentReference }}</p>

            <v-btn
              class="mb-1 mt-1"
              small
              outlined
              color="error"
              @click="
                deleteAttachedJoinedPayment({
                  joinedPaymentId: payment._id,
                  currentPaymentId: currentPayment._id,
                })
              "
              >Poista kohdistus</v-btn
            >
            <v-divider
              v-if="index < getAttachedPayments(currentPayment.payments).length - 1"
              class="mb-1 mt-1"
            ></v-divider>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-show="tab == 0" color="info" @click.prevent="join()">Tallenna</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { debounce } from "../../utils/helpers";
import { mapActions, mapState } from "vuex";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      tabs: ["Kohdista", "Kohdistetut"],
      tab: 0,
      searchInput: "",
      outgoingInput: "",
      outgoingPayment: null,
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("outgoingpayment", ["activePayments", "currentPayment"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    outgoingInput: debounce(function (newVal) {
      this.searchPayments(
        `/api/v1/outgoing-payment/search-payment?search=${newVal}&searchType=payment&apartmentId=${this.currentPayment.apartment._id}&currentPaymentId=${this.currentPayment._id}&currentTargetMonth=${this.currentPayment.targetMonth}`
      );
    }, 1000),
  },

  methods: {
    ...mapActions("outgoingpayment", [
      "deleteAttachedOtherPayment",
      "deleteJoinedPayment",
      "searchPayments",
      "joinPayment",
    ]),

    getOutgoingPayment(event) {
      this.outgoingInput = event.target.value;
    },

    getAttachedPayments(items) {
      return items.filter((item) => item.otherPayment);
    },

    async deleteOtherPayment(payload) {
      if (confirm("Haluatko varmasti poistaa kohdistuksen?")) {
        try {
          await this.deleteAttachedOtherPayment(payload);
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    async deleteAttachedJoinedPayment(payload) {
      if (confirm("Haluatko varmasti poistaa kohdistuksen?")) {
        try {
          await this.deleteJoinedPayment(payload);
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    async join() {
      if (this.$refs.form.validate()) {
        try {
          await this.joinPayment({
            currentId: this.currentPayment._id,
            newId: this.outgoingPayment._id,
          });

          this.dialog = false;
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    fixedName(item) {
      if (item.apartment) {
        const number = item.apartment.apartmentNumber ? `, ${item.apartment.apartmentNumber}` : "";
        const paymentType = item.paymentType === "payment" ? "M" : "K";
        const reference = item.paymentReference ? item.paymentReference : "";
        return (
          `${paymentType}=` +
          `${item.paymentNumber}, (${item.apartment.address}${number}) V=${reference}`
        );
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.v-card__title .headline {
  font-weight: 300;
}
</style>
