<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Toimeksiantaja</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>
    <v-card class="mt-2">
      <v-card-title>
        <p>{{ currentPartner.name }} <span v-if="!currentPartner.active">(poistettu)</span></p>
        <v-spacer></v-spacer>
        <router-link :to="`/partner/edit/${$route.params.id}`">
          <v-btn color="primary">Muokkaa</v-btn>
        </router-link>
      </v-card-title>

      <v-container fluid>
        <v-row dense>
          <v-col>
            <!-- TABS -->
            <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
              <v-tabs-slider color="parimary"></v-tabs-slider>

              <v-tab v-for="item in computedTabs" :key="item.text">
                <div>{{ item }}</div>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <!-- Components -->
        <partner-rental-contracts v-if="tab == 0"></partner-rental-contracts>
        <partner-invoices v-if="tab == 1"></partner-invoices>
        <partner-outgoing-payments v-if="tab == 2"></partner-outgoing-payments>
        <!-- <Notes v-if="tab == 1"></Notes> -->
      </v-container>
    </v-card>
  </div>
</template>

<script>
import PartnerRentalContracts from "../../components/Partner/PartnerRentalContracts";
import PartnerInvoices from "../../components/Partner/PartnerInvoices";
import PartnerOutgoingPayments from "../../components/Partner/PartnerOutgoingPayments";
import mixins from "../../mixins/mixins";
import { mapActions, mapState } from "vuex";
// import Notes from "../../components/Notes";

export default {
  title: "Toimeksiantajan hallinta",

  components: {
    PartnerRentalContracts,
    PartnerInvoices,
    PartnerOutgoingPayments,
    // Notes,
  },

  mixins: [mixins],

  data() {
    return {
      search: "",
      tab: 0,
      tabs: ["Vuokrasopimukset", "Laskut", "Lähtevät maksut"],
    };
  },

  created() {
    this.getOnePartner("/api/v1/partner/getOne/" + this.$route.params.id);
  },

  watch: {
    "$route.params.id": {
      handler: function () {
        this.getOnePartner("/api/v1/partner/getOne/" + this.$route.params.id);
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapState("partner", ["currentPartner"]),
    ...mapState("account", ["currentUser"]),

    computedTabs() {
      return this.tabs.filter((tab) => {
        if (this.currentUser.currentAccount.settings.outgoingPayments) {
          return tab;
        } else {
          return tab != "Lähtevät maksut";
        }
      });
    },
  },

  methods: {
    ...mapActions("partner", ["getOnePartner"]),
  },
};
</script>

<style scoped></style>
