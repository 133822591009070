var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Maksun nro, vuokrakohde, toimeksiantaja, viite...","single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticStyle:{"max-width":"360px"}},[_c('v-checkbox',{attrs:{"dense":"","label":"Näytä maksetut","hide-details":""},model:{value:(_vm.showPaidPayments),callback:function ($$v) {_vm.showPaidPayments=$$v},expression:"showPaidPayments"}}),_c('v-checkbox',{attrs:{"dense":"","label":"Näytä tarkistetut","hide-details":""},model:{value:(_vm.showUpdatedPayments),callback:function ($$v) {_vm.showUpdatedPayments=$$v},expression:"showUpdatedPayments"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.payments,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.lastChecked",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateAndTime(item.lastChecked)))])]}},{key:"item.targetMonth",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateMonthName(item.targetMonth)))])]}},{key:"item.paymentNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('strong',[_vm._v("# "+_vm._s(item.paymentNumber))])])]}},{key:"item.apartment",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/apartment/overview/" + (item.apartment._id))}},[(item.apartment)?_c('span',[_vm._v(" "+_vm._s(item.apartment.address)),(item.apartment.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(item.apartment.apartmentNumber))]):_vm._e()]):_vm._e()])]}},{key:"item.apartment.owners",fn:function(ref){
var item = ref.item;
return [(item.apartment.owners.length == 0)?_c('span',{staticClass:"error--text"},[_vm._v("Ei omistajia")]):_vm._l((item.apartment.owners),function(owner,idx){return _c('div',{key:'a' + idx},[(owner.onModel == 'Partner' && String(owner.ownerId._id) != _vm.$route.params.id)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/partner/overview/" + (owner.ownerId._id))}},[_c('span',[_vm._v(_vm._s(owner.name))])]):_c('span',[_vm._v(_vm._s(owner.name))])],1)})]}},{key:"item.invoices",fn:function(ref){
var item = ref.item;
return [_vm._l((item.invoices),function(invoice,idx){return _c('span',{key:'b' + idx},[_c('span',[_vm._v(_vm._s(invoice.invoiceNumber))]),(idx < item.invoices.length - 1)?_c('span',[_vm._v(" | ")]):_vm._e()])}),(item.invoices.length > 0)?_c('div',{staticClass:"success--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.invoiceAmount))+" ")]):_vm._e()]}},{key:"item.refundInvoices",fn:function(ref){
var item = ref.item;
return [_vm._l((item.refundInvoices),function(invoice,idx){return _c('div',{key:'c' + idx},[_c('span',[_vm._v(_vm._s(invoice.invoiceNumber))]),(idx < item.refundInvoices.length - 1)?_c('span',[_vm._v(" | ")]):_vm._e()])}),(item.refundInvoices.length > 0)?_c('div',{staticClass:"error--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.refundsAmount))+" ")]):_vm._e()]}},{key:"item.joinedTo",fn:function(ref){
var item = ref.item;
return [(item.joinedTo)?_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"primary--text",staticStyle:{"cursor":"pointer"}},'div',attrs,false),on),[_c('span',[_vm._v(" # "+_vm._s(item.joinedTo.paymentNumber)+" ")])])]}}],null,true)},[_c('h4',{staticClass:"mb-1"},[_vm._v("Kohdistetut maksut")]),_c('p',{staticStyle:{"margin-bottom":"6px","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.formatDateMonthName(item.joinedTo.targetMonth))+" ")]),_c('p',[_vm._v("Maksu: # "+_vm._s(item.joinedTo.paymentNumber))])]):_c('span')]}},{key:"item.receiptsAmount",fn:function(ref){
var item = ref.item;
return [(item.receipts.length > 0)?_c('v-tooltip',{attrs:{"top":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"primary--text",staticStyle:{"cursor":"pointer"}},'div',attrs,false),on),[(!_vm.includesInActiveReceipts(item))?_c('span',[_vm._v("Erittely")]):_c('span',[_c('v-icon',{attrs:{"color":"warning","small":""}},[_vm._v("mdi-alert-circle")])],1)])]}}],null,true)},[_c('h4',{staticClass:"mb-1"},[_vm._v("Tuotteet")]),_vm._l((item.receipts),function(receipt,index){return _c('div',{key:index + 'e'},[_c('strong',[_vm._v(_vm._s(receipt.name))]),_vm._l((receipt.products),function(prod,index){return _c('div',{key:index + 'f'},[_c('span',{class:{ 'deleted-receipt': !receipt.active }},[_vm._v(_vm._s(prod.name)+" "+_vm._s(_vm.formatCurrency(prod.count * prod.amount)))])])})],2)})],2):_vm._e(),(item.receipts.length > 0)?_c('span',{staticClass:"error--text font-weight-bold"},[_vm._v(_vm._s(_vm.formatCurrency(item.receiptsAmount)))]):_vm._e()]}},{key:"item.payments",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({
          'text-decoration': item.joinedTo && item.paymentType == 'payment' ? 'line-through' : '',
        })},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.calcTotalPayments(item)))+" ")]),(item.payments.length > 0 || item.joinedPayments.length > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$store.state.outgoingpayment.currentPayment = Object.assign({}, item);
          _vm.dialogPayments = true;}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-edit-outline")])],1):_vm._e()]}},{key:"item.openAmount",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",class:_vm.calcOpenAmount(item) > 0 && item.paymentType == 'payment'
            ? 'success--text'
            : _vm.calcOpenAmount(item) < 0 && item.paymentType == 'payment'
            ? 'error--text'
            : _vm.calcOpenAmount(item) == 0
            ? 'success--text'
            : 'error--text'},[_c('div',{style:({
            'text-decoration':
              item.joinedTo && item.paymentType == 'payment' ? 'line-through' : '',
          })},[_c('span',[_vm._v(_vm._s(_vm.getPaymentType(item)))]),_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.calcOpenAmount(item)))+" ")])])]}},{key:"item.paymentReference",fn:function(ref){
          var item = ref.item;
return [(item.paymentReference)?_c('span',[_vm._v(_vm._s(item.paymentReference))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('outgoingPayment', 'edit'))?_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){_vm.$store.state.outgoingpayment.currentPayment = Object.assign({}, item);
            _vm.editPaymentDialog = true;}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.isAuthorized('outgoingPayment', 'create'))?_c('v-icon',{attrs:{"title":"Tee ohisuoritus (maksu)","small":""},on:{"click":function($event){_vm.$store.state.outgoingpayment.currentPayment = Object.assign({}, item);
            _vm.makePaymentDialog = true;}}},[_vm._v("mdi-cash-multiple")]):_vm._e(),(_vm.isAuthorized('outgoingPayment', 'delete'))?_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){_vm.selected = Object.assign({}, item);
            _vm.deleteItem(item);}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei lähteviä maksuja")])]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Poista lähtevä maksu # "+_vm._s(_vm.selected.paymentNumber)+" ")]),_c('v-card-text',[_c('p',[_vm._v("Maksu sisältää kuitteja. Mihin tilaan kuitit asetetaan maksun poistamisen jälkeen?")])]),_c('v-card-text',[_c('v-select',{attrs:{"label":"Kuittien tila","items":[
            { text: 'Lähtevät maksut käytössä', val: true },
            { text: 'Lähtevät maksut pois päältä', val: false } ],"item-text":"text","item-value":"val","outlined":"","dense":""},model:{value:(_vm.subtractReceiptFromOutgoing),callback:function ($$v) {_vm.subtractReceiptFromOutgoing=$$v},expression:"subtractReceiptFromOutgoing"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Poistu")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.deletePayment({ paymentId: _vm.selected._id, subtractReceiptFromOutgoing: _vm.subtractReceiptFromOutgoing });
            _vm.deleteDialog = false;}}},[_vm._v("Poista maksu")])],1)],1)],1),_c('all-payments',{model:{value:(_vm.dialogPayments),callback:function ($$v) {_vm.dialogPayments=$$v},expression:"dialogPayments"}}),_c('upload-csv-file',{model:{value:(_vm.uploadFileDialog),callback:function ($$v) {_vm.uploadFileDialog=$$v},expression:"uploadFileDialog"}}),_c('edit-payment',{model:{value:(_vm.editPaymentDialog),callback:function ($$v) {_vm.editPaymentDialog=$$v},expression:"editPaymentDialog"}}),_c('make-payment',{model:{value:(_vm.makePaymentDialog),callback:function ($$v) {_vm.makePaymentDialog=$$v},expression:"makePaymentDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }