<template>
  <!-- Dialog payments -->
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title>Suoritukset maksulle # {{ currentPayment.paymentNumber }} </v-card-title>

      <v-card-text class="card-comments">
        <!-- 2. Get all attached other payments -->
        <div
          v-for="(item, idx) in getStatementPayments(currentPayment.payments.slice().reverse())"
          :key="idx + 'a'"
        >
          <!-- From statement data or bypass payment, normal -->
          <div class="paid-amounts" style="margin-bottom: -1px; font-size: 14px">
            <p v-if="item.message">Ohisuoritus</p>
            <p v-if="!item.message">Kohdistettu tiliotteelta</p>
            <p>
              <strong>Kirjauspäivä: {{ formatDate(item.date) }}</strong>
            </p>
            <p>
              <strong>Summa: {{ formatCurrency(item.amount) }}</strong>
            </p>
            <small>{{ item.message }}</small>
            <div class="mt-1">
              <v-btn small color="error" outlined @click="deletePayment(item)"
                >Poista suoritus</v-btn
              >
            </div>
          </div>

          <v-divider class="mt-1 mb-1"></v-divider>
        </div>

        <!-- 1. Get all joinedpayments -->
        <div class="mb-1" v-for="(payment, idx) in currentPayment.joinedPayments" :key="idx + 'b'">
          <div class="paid-amounts" style="margin-bottom: -1px; font-size: 14px">
            <p>Kohdistettu toisesta maksusta</p>
            <p>
              <strong>Maksu: # {{ payment.paymentNumber }}</strong>
            </p>
            <p>
              <strong>Kohdekuukausi: {{ formatDateMonthName(payment.targetMonth) }}</strong>
            </p>
            <p>
              <strong>Viite: {{ payment.paymentReference }}</strong>
            </p>
            <p>
              <strong>Avoinna: {{ formatCurrency(payment.openAmount) }}</strong>
            </p>
          </div>

          <v-divider class="mb-1 mt-1"></v-divider>
        </div>

        <!-- 2. Other payments -->
        <div
          v-for="(item, idx) in getOtherPayments(currentPayment.payments.slice().reverse())"
          :key="idx + 'c'"
        >
          <div class="paid-amounts" style="margin-bottom: -1px; font-size: 14px">
            <p>Kohdistettu kohdistumattomalta suoritukselta</p>
            <p>
              <strong>Nimi: {{ item.otherPayment.name }}</strong>
            </p>
            <p>
              <strong> Kirjauspäivä: {{ formatDate(item.date) }} </strong>
            </p>
            <p>
              <strong>Viite: {{ item.otherPayment.reference }}</strong>
            </p>
            <p>
              <strong>Summa: {{ formatCurrency(item.amount) }}</strong>
            </p>
            <div class="mt-1">
              <v-btn small color="error" outlined @click="deletePayment(item)"
                >Poista kohdistus</v-btn
              >
            </div>
          </div>

          <v-divider class="mt-1 mb-1"></v-divider>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState, mapActions } from "vuex";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("outgoingpayment", ["currentPayment"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapActions("outgoingpayment", ["deleteManualPayment"]),

    getOtherPayments(payments) {
      return payments.filter((item) => item.otherPayment);
    },

    getStatementPayments(payments) {
      return payments.filter((item) => !item.otherPayment);
    },

    async deletePayment(item) {
      let message = item.otherPayment
        ? `kohdistuksen ${item.otherPayment.name} ${item.amount} €`
        : `suorituksen ${item.amount} €`;

      if (confirm(`Haluatko varmasti poistaa ${message}?`)) {
        try {
          this.deleteManualPayment({
            paymentId: item._id,
            otherPaymentId: item.otherPayment ? item.otherPayment._id : null,
            outgoingPaymentId: this.currentPayment._id,
          });
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped>
.v-card__title .headline {
  font-weight: 300;
}
</style>
